import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    colors: {
        bg: {
            main: '#030F23',
            secondary: '#4E96EC',
            shadow: '#6592E0',
        },
    },
    styles: {
        global: {
            body: {
                backgroundColor: 'bg.main',
            },
        },
    },
});

//  const themevars = {
//     colors: {
//         bgcolor: '#030F23'
//     }
// }

// export default themevars
// export default {
//     colors: {
//       transparent: 'transparent',
//       black: '#000',
//       white: '#fff',
//       gray: {
//         50: '#f7fafc',
//         // ...
//         900: '#171923',
//       },
//       // ...
//     },
//   }

export default theme;
