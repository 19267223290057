import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { BACKEND_HOST } from '../config';

export default function useOrganizations() {
    const {
        isLoading,
        error,
        isError,
        data: allOrganizationsData,
    } = useQuery({
        queryKey: ['allorganizations'],
        queryFn: async () => {
            const { data } = await axios.get(`${BACKEND_HOST}/api/organization`);
            return data;
        },
    });

    // const deleteOrganization = useMutation(
    //     (organizationId: string) =>
    //         axios.delete(`${BACKEND_HOST}/api/organization/${organizationId}`),
    //     {
    //         onSuccess: () => {
    //             toast({
    //                 title: 'Success. 😍',
    //                 position: 'top',
    //                 description: `You've successfully deleted this organization. `,
    //                 status: 'success',
    //                 duration: 9000,
    //                 isClosable: true,
    //             });
    //             queryClient.refetchQueries(['allorganizations'], { exact: false });
    //         },
    //     }
    // );

    return {
        allOrganizationsData,
        isLoading,
        isError,
        error,
        // deleteOrganization,
    };
}
