import { Button, Heading, Image, Stack, Text } from '@chakra-ui/react';
import { ITeamMember } from './TeamMember.Interface';

const AboutTeamCard = ({ name, id, image, title }: ITeamMember) => {
    return (
        <Stack align="center">
            <Image
                src={image}
                alt={name}
                borderRadius="30px"
                w="229px"
                h="229px"
                bg="#D9D9D9"
                border="9px solid #4E96EC"
                boxShadow="0px 0px 10.7px 6px #4E96EC"
            />
            <Heading fontSize="large" color="#EAF1F7">
                {name}
            </Heading>
            <Text color="#FFFFFF">{title}</Text>
            <Button variant="solid" colorScheme="blue" p={2}>
                Contact
            </Button>
        </Stack>
    );
};

export default AboutTeamCard;
