import { useCallback, useEffect, useRef } from 'react';
import { Flex } from '@chakra-ui/react';
import usePixels from '../../hooks/usePixels';

const CANVAS_W = 1500;
const CANVAS_H = 3000;

type Pixel = {
    xCorr: number;
    yCorr: number;
    image?: string;
    donations: string[];
    color: string;
    isDrawn?: boolean;
};

const RenderPixelMap = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const { allPixelsData, isLoading, refetch } = usePixels();

    const renderPixels = useCallback(() => {
        const canvas = canvasRef.current;

        if (!canvas || isLoading || !allPixelsData) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const pixelSize = 20;
        // ctx.scale(1, 1);
        // Iterate over each pixel and draw new ones
        allPixelsData.forEach((pixel: Pixel) => {
            if (!pixel.isDrawn) {
                const x = pixel.xCorr;
                const y = pixel.yCorr;
                // default size was 1, but this makes it n x n
                const size = pixelSize - 1; // Subtract 1 to create a border between pixels
                ctx.fillStyle = pixel.color;
                ctx.fillRect(x, y, size, size); // Draw a n x n pixel

                pixel.isDrawn = true;
            }
        });
    }, [isLoading, allPixelsData]);

    useEffect(() => {
        renderPixels();

        const intervalId = setInterval(() => {
            refetch().then(() => renderPixels());
        }, 10000); // refresh pixels every 10 seconds

        return () => clearInterval(intervalId);
    }, [refetch, renderPixels]);

    return (
        <Flex
            // border={'yellow 1pt solid'}
            justifyContent={{ base: 'center', lg: 'center', md: 'flex-end' }}
            alignItems="center"
        >
            <canvas
                id="pixelCanvas"
                ref={canvasRef}
                // The canvas width and height are set to the actual pixel size.
                // To resize the map, adjust the style property of the canvas element.
                // The width and height props are used for canvas drawing calculations.
                width={CANVAS_W}
                height={CANVAS_H}
                style={{ width: '350px', height: '700px' }} // Adjusted style
            ></canvas>
        </Flex>
    );
};

export default RenderPixelMap;
