import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
} from '@chakra-ui/react';
import { IFAQ } from '../../assets/faqsArray';

const FAQItem = ({ question, answer }: IFAQ) => {
    return (
        <AccordionItem lineHeight={'normal'} width="100%">
            <h2>
                <AccordionButton my={3} fontSize="22px" width="100%">
                    <Box flex="1" textAlign="left" color="white">
                        {question}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={8} color="blue.100" fontSize="22px" width="100%">
                {answer}
            </AccordionPanel>
        </AccordionItem>
    );
};

export default FAQItem;
