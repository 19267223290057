export interface IFAQ {
    id: number;
    question: string;
    answer: string;
}

const FAQsArray: IFAQ[] = [
    {
        id: 1,
        question: 'Where can I donate?',
        answer: "Visit the 'organizations' page to see a list of organizations you can donate to.",
    },
    {
        id: 2,
        question: 'Does 100% of my donation go to the organization?',
        answer: 'Yes! PBP helps you make the donation, but does not take any percentage of the funds. 100% of your donation goes toward the organization you choose.',
    },
    {
        id: 3,
        question: 'Is it secure to donate through your site?',
        answer: "Yes. Your payment security is our top priority. You can rest assured that your donation goes directly via Stripe's secure payment system to the organization you choose.",
    },
    {
        id: 4,
        question: 'What if I want to donate to an organization that is not on the list?',
        answer: "Great question! Fill out the 'contact us' form and let us know in the message. We'll do some research to confirm it's a good fit, and let you know.",
    },
];

export default FAQsArray;
