import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Box, ChakraProvider, Stack } from '@chakra-ui/react';
import Navbar from './components/Navigation/Navbar';
import routes from './constants/routes';
import Footer from './components/Navigation/footer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import theme from './theme';

const queryClient = new QueryClient({
    defaultOptions: {},
});

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ChakraProvider
                theme={theme}
                toastOptions={{
                    defaultOptions: { position: 'top', duration: 9000, isClosable: true },
                }}
            >
                <Stack
                    overflowX={'hidden'}
                    border="none"
                    p={0}
                    bg="bg.main"
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    h="100vh"
                    w="100vw"
                    color="#EAF1F7"
                >
                    <BrowserRouter>
                        <Box as="main" w="1536px" maxW="100%" mx="auto" p={10}>
                            <Navbar />

                            <Routes>
                                {routes.map((route) => (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        element={route.element}
                                    />
                                ))}
                                <Route path="*" element={<Navigate to="/" />} />
                            </Routes>
                        </Box>
                        <Footer />
                    </BrowserRouter>
                </Stack>
            </ChakraProvider>
        </QueryClientProvider>
    );
}

export default App;
