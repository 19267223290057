import { Accordion } from '@chakra-ui/react';
import FAQItem from './FAQItem';
import FAQsArray from '../../assets/faqsArray';

const FAQAccordion = () => {
    return (
        <Accordion mt={3} allowToggle maxW="750px" mr={0} width={'100%'}>
            {FAQsArray.map((faq) => (
                <FAQItem key={faq.id} {...faq} />
            ))}
        </Accordion>
    );
};

export default FAQAccordion;
