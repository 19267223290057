import { Card, CardBody, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import AdvertiseForm from '../components/AdvertiseWithUs/AdvertiseForm';

const AdvertisePage = () => {
    return (
        <Stack>
            <Heading>Advertise with us!</Heading>
            <Text>
                Place your ad on PBP's homepage and reach thousands of potential customers. 100% of
                your ad fees go toward a donation via PBP.
            </Text>{' '}
            <Card alignSelf="center" w="fit-content">
                <CardBody>
                    <Flex align="center" justify="center">
                        <AdvertiseForm />
                    </Flex>
                </CardBody>
            </Card>
        </Stack>
    );
};

export default AdvertisePage;
