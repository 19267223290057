import { Card, CardBody, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import ContactForm from '../components/ContactUs/Contactform';

const ContactUsPage = () => {
    return (
        <Stack>
            <Heading>Contact us!</Heading>
            <Text>
                Got a question? Want to share a thought, story, or idea? Reach out! We'd love to
                hear from you.
            </Text>{' '}
            <Card alignSelf="center" w="fit-content">
                <CardBody w="100%">
                    <Flex align="center" justify="center">
                        <ContactForm />
                    </Flex>
                </CardBody>
            </Card>
        </Stack>
    );
};

export default ContactUsPage;
