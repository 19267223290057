import { Heading, Stack, Box, SimpleGrid, Spinner } from '@chakra-ui/react';
import AmbassadorCard from './AmbassadorCard';
import { IAmbassador } from './Ambassador.Interface';
import useAmbassadors from '../../../hooks/useAmbassadors';
// import allAmbassadorsData from '../../../assets/pre-api-assets/ambassadorsArray';

const AmbassadorsList = () => {
    const {
        isLoading: isLoadingAmbassadors,
        allAmbassadorsData,
        isError,
        error,
    } = useAmbassadors();

    if (isLoadingAmbassadors) {
        return (
            <>
                <Heading color="#EAF1F7">Current Ambassadors</Heading>

                <Spinner
                    mt={20}
                    p={20}
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                />
            </>
        );
    }

    if (isError) {
        return <Box pt={20}>Error: {error?.message}</Box>;
    }

    return (
        <Stack
            spacing={10}
            textAlign="left"
            // alignItems={'flex-start'}
            w={'100%'}
            color="#EAF1F7"
        >
            <Heading color="#EAF1F7">Current Ambassadors</Heading>
            <SimpleGrid spacing={10} templateColumns="repeat(auto-fill, minmax(200px, 1fr))">
                {allAmbassadorsData ? (
                    allAmbassadorsData.map((ambassador: IAmbassador) => (
                        <AmbassadorCard key={ambassador._id} {...ambassador} />
                    ))
                ) : (
                    <>No Ambassadors Yet.</>
                )}
            </SimpleGrid>
        </Stack>
    );
};

export default AmbassadorsList;
