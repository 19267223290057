import { Button, Flex, Heading, Stack, Text } from '@chakra-ui/react';

const BecomeAmbassador = () => {
    return (
        <Flex
            justifyContent={'center'}
            gap={10}
            alignContent={'center'}
            textAlign="left"
            w={'100%'}
            mt={10}
        >
            <Stack
                justify={'space-between'}
                spacing={10}
                alignContent={'center'}
                textAlign="left"
                color="#EAF1F7"
            >
                <Heading>Become an Ambassador</Heading>
                <Text fontWeight="300" fontSize="26px" lineHeight="34px">
                    Peace By Piece Ambassadors are the frontlines of this project. As an ambassador,
                    you will lead program outreach in your community and beyond.
                </Text>
            </Stack>
            <Button variant="solid" colorScheme="blue" p={10}>
                <Text fontSize="xl" color="#EAF1F7" text-shadow="0px 4px 4px rgba(0, 0, 0, 0.25)">
                    Apply
                </Text>
            </Button>
        </Flex>
    );
};
export default BecomeAmbassador;
