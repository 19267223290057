import { Link as ChakraLink, Flex, Stack } from '@chakra-ui/react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import routes from '../../constants/routes';
import { navigationFilterFunc } from './navigationFilter';
import { useState } from 'react';
import DynamicSVG from './sampleDynamicCode';

const Navbar = () => {
    const [percentage, setPercentage] = useState(54);
    const location = useLocation();

    return (
        <Flex
            as="header"
            m={5}
            mb={0}
            bg="bg.main"
            justifyContent={'space-between'}
            gap={5}
            w="100%"
        >
            <Flex
                as={'nav'}
                p={4}
                ml={5}
                bg="#081C3D"
                w={'100%'}
                h={'min-content'}
                borderRadius="40px"
                boxShadow="0px 4px 45px #6592E0"
                justifyContent={'space-around'}
            >
                {routes.filter(navigationFilterFunc('header')).map((route, index) => (
                    <ChakraLink
                        flexBasis={route.flexBasis ? route.flexBasis : 1}
                        color="#EAF1F7"
                        bg={location.pathname === route.path ? '#4E96EC' : 'transparent'}
                        textAlign="center"
                        fontWeight={'bold'}
                        rounded={'md'}
                        m={0}
                        p={3}
                        textDecoration="none"
                        _hover={{
                            bg: '#4E96EC',
                        }}
                        _focus={{
                            bg: '#4E96EC',
                        }}
                        mr={
                            index !== routes.filter(navigationFilterFunc('header')).length - 1
                                ? 2
                                : 0
                        }
                        style={{
                            // boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)',
                            boxShadow:
                                location.pathname === route.path
                                    ? '0px 4px 15px rgba(0, 0, 0, 0.5)'
                                    : 'none',
                            borderRadius: '40px',
                        }}
                        transition="background-color .2s ease, box-shadow 0.2s ease, border-radius .2s ease"
                        as={ReactRouterLink}
                        key={route.path}
                        to={route.path}
                        aria-label={route.label}
                    >
                        {route.label}
                    </ChakraLink>
                ))}
            </Flex>

            <Stack alignSelf="flex-end" alignItems={'center'} justifySelf="right" ml={10}>
                <ChakraLink as={ReactRouterLink} key={'home'} to={'/'} aria-label={'home'}>
                    {/* <Image src={logo} alt="progress map" h={100} /> */}
                    <DynamicSVG percentage={percentage} />
                </ChakraLink>
            </Stack>
        </Flex>
    );
};

export default Navbar;
