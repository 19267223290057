export interface ILink {
    path: string;
    Icon: string;
}

const links: ILink[] = [
    {
        Icon: 'https://twitter.com',
        path: 'https://twitter.com/',
    },
    {
        Icon: 'https://linkedin.com',
        path: 'https://linkedin.com/',
    },
    {
        Icon: 'https://instagram.com',
        path: 'https://instagram.com/',
    },
    {
        Icon: 'https://facebook.com',
        path: 'https://www.facebook.com/',
    },
];

export default links;
