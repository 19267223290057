import { SimpleGrid } from '@chakra-ui/react';
import team from '../../../assets/teamArray';
import AboutTeamCard from './AboutTeamCard';

const AboutTeamGrid = () => {
    return (
        <SimpleGrid
            alignItems="center"
            spacing="100px"
            templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
            mt={10}
        >
            {team.map((teamMember) => (
                <AboutTeamCard key={teamMember.id} {...teamMember} />
            ))}
        </SimpleGrid>
    );
};

export default AboutTeamGrid;
