import * as Yup from 'yup';

export const donateValidationSchema = Yup.object({
    numberOfPixels: Yup.number().min(1, 'Choose at least 1 pixel to donate.').required('Required'),
    organization: Yup.string().required('Required'),
    hoverText: Yup.string().max(30, '30 characters max'),
    hoverUrlLink: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url'
    ),
});
