import Aboutpage from '../pages/Aboutpage';
import Home from '../pages/Home';
import Organizations from '../pages/OrganizationsPage';
import FAQPage from '../pages/FAQPage';
import AdvertisePage from '../pages/AdvertisePage';
import ContactUsPage from '../pages/Contactus';
import Ambassadors from '../pages/AmbassadorsPage';
import PrivacyPolicyPage from '../pages/Contactus';
import TermsandConditionsPage from '../pages/Contactus';
export interface IRoute {
    path: string;
    element: JSX.Element;
    label: string;
    protected: boolean;
    location: string;
    flexBasis?: number | string;
}

const routes: IRoute[] = [
    {
        path: '/',
        element: <Home />,
        label: 'DONATE',
        protected: false,
        location: 'header',
        flexBasis: 1,
    },
    {
        path: '/:id',
        element: <Home />,
        label: 'DONATE',
        protected: false,
        location: 'none',
        flexBasis: 1,
    },
    // {
    //   path: "/admin",
    //   element: <NewAmbassaore />,
    //   label: "Admin Dashboard",
    //   protected: true,
    //   admin: true,
    //   location: "header",
    // },
    {
        path: '/faq',
        element: <FAQPage />,
        label: 'FAQ',
        protected: false,
        location: 'header',
    },
    {
        path: '/organizations',
        element: <Organizations />,
        label: 'ORGANIZATIONS',
        protected: false,
        location: 'header',
    },
    {
        path: '/ambassadors',
        element: <Ambassadors />,
        label: 'AMBASSADORS',
        protected: false,
        location: 'header',
    },
    {
        path: '/about',
        element: <Aboutpage />,
        label: 'ABOUT',
        protected: false,
        location: 'header',
    },
    {
        path: '/contact',
        element: <ContactUsPage />,
        label: 'Contact',
        protected: false,
        location: 'footer',
    },
    {
        path: '/advertise',
        element: <AdvertisePage />,
        label: 'Advertise with Us',
        protected: false,
        location: 'footer',
    },
    {
        path: '/faq',
        element: <FAQPage />,
        label: 'FAQ',
        protected: false,
        location: 'footer',
    },
    {
        path: '/termsandconditions',
        element: <TermsandConditionsPage />,
        label: 'Terms and Conditions',
        protected: false,
        location: 'footer',
    },
    {
        path: '/privacypolicy',
        element: <PrivacyPolicyPage />,
        label: 'Privacy Policy',
        protected: false,
        location: 'footer',
    },
];

export default routes;
