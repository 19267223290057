import { Heading, Stack, Text } from '@chakra-ui/react';
import OrganizationsList from '../components/Organizations/OrganizationsList';

const OrganizationsPage = () => {
    return (
        <Stack>
            <Stack spacing={10}>
                <Heading>Who We Support</Heading>
                <Text fontWeight="300" fontSize="26px" lineHeight="34px">
                    Peace by Peace partners with verified organizations whose mission is to support
                    families and individuals, both on the front lines and at home.
                </Text>
            </Stack>
            <OrganizationsList />
        </Stack>
    );
};

export default OrganizationsPage;
