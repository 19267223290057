import { Heading, Image, Stack, Text } from '@chakra-ui/react';
import { IAmbassador } from './Ambassador.Interface';

const AmbassadorCard = ({ firstName, lastName, profilePic, location }: IAmbassador) => {
    return (
        <Stack maxW="max-content" h={'fit-content'}>
            <Image
                src={
                    profilePic
                        ? profilePic
                        : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnR-Y-tzqD8SVVz6tG7flPjFj8oKJe7LieBrXX7PBMLGEstCFHCUVmquTyVQ&s'
                }
                alt={firstName}
                borderRadius="30px"
                w="229px"
                h="229px"
                bg="#D9D9D9"
                border="9px solid #4E96EC"
                boxShadow="0px 0px 10.7px 6px #4E96EC"
            />
            <Stack alignItems={'center'} textAlign={'center'}>
                <Heading size="md" color="#EAF1F7" letterSpacing="0.02em">
                    {firstName} {lastName}
                </Heading>
                <Text color="white">{location}</Text>
            </Stack>
        </Stack>
    );
};

export default AmbassadorCard;
