import { Button, Heading, Image, Stack, Text, Flex } from '@chakra-ui/react';
import { IOrganization } from './Organization.Interface';
import { useNavigate } from 'react-router-dom';

const OrganizationCard = ({ name, image, about, donations, color, _id }: IOrganization) => {
    const navigate = useNavigate();

    const goDonate = () => {
        navigate(`/${_id}`);
    };

    return (
        <Flex
            gap={20}
            justifyContent={'center'}
            alignItems={'center'}
            textAlign="left"
            w={'100%'}
            wrap="wrap"
        >
            <Image
                src={image}
                alt={name}
                borderRadius="360px"
                w="250px"
                h="250px"
                bg="#D9D9D9"
                boxShadow="0px 0px 50px #6592E0"
            />
            <Stack color="#FFFFFF" maxW={'250px'}>
                <Heading fontWeight="600" fontSize="34px" lineHeight="58px">
                    {name}
                </Heading>
                <Text fontWeight="300" fontSize="20px" lineHeight="34px">
                    {about}
                </Text>

                <Text fontWeight="200" lineHeight="18px" color="#FFFFFF">
                    {donations?.length} donations and counting{' '}
                </Text>
                <Button variant="solid" width={'fit-content'} colorScheme="blue" onClick={goDonate}>
                    Donate
                </Button>
            </Stack>
        </Flex>
    );
};

export default OrganizationCard;
