// modify  the file @types/react-app-env.d.ts

export const BACKEND_HOST: string = process.env.REACT_APP_BACKEND_HOST || 'http://localhost:80';

export const BASEPATHS: Record<string, string> = {
    organization: 'organization',
    ambassador: 'ambassador',
    pixel: 'pixel',
    donation: 'donation',
    user: 'user',
};
