import React, { useState, useEffect, useRef } from 'react';
import { pathDataGolan } from './pathData';
import { useTheme } from '@chakra-ui/react';

interface DynamicSVGProps {
    percentage: number;
}

const DynamicSVG: React.FC<DynamicSVGProps> = ({ percentage }) => {
    const [filledHeight, setFilledHeight] = useState<number>(0);
    const innerFillPathRef = useRef<SVGPathElement>(null);
    const outerShapePathRef = useRef<SVGPathElement>(null);
    const theme = useTheme().colors;

    useEffect(() => {
        const innerPathElement = innerFillPathRef.current;
        const outerPathElement = outerShapePathRef.current;

        if (innerPathElement && outerPathElement) {
            const filled = (percentage / 100) * 1118; // Calculate filled height
            setFilledHeight(filled);
        }
    }, [percentage]);

    return (
        <svg width="109" height="232" viewBox="0 0 495 1118">
            {/* Define mask */}
            <mask id="mask">
                {/* White background */}
                <path ref={outerShapePathRef} d={pathDataGolan} fill="white" />
            </mask>

            {/* Outline background */}
            <path
                ref={innerFillPathRef}
                d={pathDataGolan}
                fill={'white'}
                opacity={0.1}
                fillRule="evenodd"
            />
            {/* Filled area */}
            <rect
                x="0"
                y={1118 - filledHeight}
                width="100%"
                height={filledHeight}
                fill="white"
                fillRule="evenodd"
                mask="url(#mask)"
            />

            {/* Inner fill area with mask */}
            <path
                ref={innerFillPathRef}
                d={pathDataGolan}
                stroke={theme.bg.secondary}
                style={{ boxShadow: '0px 40px 45px #6592E0' }}
                strokeWidth={5}
                fill={'none'}
                fillRule="evenodd"
            />
            <text
                x="38%"
                y="54%"
                textAnchor="middle"
                fill={theme.bg.secondary}
                fontSize="140"
                fontWeight="bolder"
                dy=".3em"
            >
                {`${percentage}%`}
            </text>
        </svg>
    );
};

export default DynamicSVG;
