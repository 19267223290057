import { Heading, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react';
import AboutTeam from '../components/About/Team/AboutTeam';

const AboutPage = () => {
    return (
        <>
            <Stack spacing={10}>
                <Heading>Peace by Piece is...</Heading>
                <Text fontWeight="400" fontSize="20px" lineHeight="44px">
                    <UnorderedList>
                        <ListItem>Dedicated to connecting people with organizations</ListItem>
                        <ListItem>Driven by a mission to support Israel </ListItem>
                        <ListItem>Run by people like you</ListItem>
                        <ListItem>Safe to ensure your security </ListItem>
                    </UnorderedList>
                </Text>
                <AboutTeam />
            </Stack>
        </>
    );
};

export default AboutPage;
