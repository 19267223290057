import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { BACKEND_HOST } from '../config';

export default function useAmbassadors() {
    const {
        isLoading,
        error,
        isError,
        data: allAmbassadorsData,
    } = useQuery({
        queryKey: ['allambassadors'],
        queryFn: async () => {
            const { data } = await axios.get(`${BACKEND_HOST}/api/ambassador`);
            return data;
        },
    });

    return {
        allAmbassadorsData,
        isLoading,
        isError,
        error,
    };
}
