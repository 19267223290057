import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Stack,
    Textarea,
    useToast,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { contactValidationSchema } from './contactUsValidation';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
    const toast = useToast();

    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                message: '',
            }}
            validationSchema={contactValidationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                try {
                    emailjs
                        .send(
                            process.env.REACT_APP_EMAIL_SERVICE_ID || 'undefined',
                            process.env.REACT_APP_CONTACT_US_TEMPLATE_ID || 'undefined',
                            values,
                            process.env.REACT_APP_EMAIL_PUBLIC_KEY || 'undefined'
                        )
                        .then(() => {
                            toast({
                                title: 'Success!',
                                description: 'Your email has been sent. Thanks for reaching out!',
                                status: 'success',
                            });
                            setSubmitting(false);
                            resetForm();
                        });
                } catch {
                    toast({
                        title: 'Uh Oh!',
                        description: 'Something went wrong. Please try again.',
                        status: 'error',
                    });
                    setSubmitting(false);
                }
            }}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                isValid,
                dirty,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <Stack align="center" justifyItems={'center'} p={5}>
                        {' '}
                        <Field name="name">
                            {() => (
                                <FormControl isInvalid={errors?.name !== undefined && touched.name}>
                                    <FormLabel htmlFor="name">Name</FormLabel>
                                    <Input
                                        variant="filled"
                                        name="name"
                                        type="text"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.name && touched.name ? (
                                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                                    ) : null}
                                </FormControl>
                            )}
                        </Field>
                        <Field name="email">
                            {() => (
                                <FormControl
                                    isInvalid={errors?.email !== undefined && touched.email}
                                >
                                    <FormLabel htmlFor="email">Email</FormLabel>
                                    <Input
                                        variant="filled"
                                        name="email"
                                        type="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.email && touched.email ? (
                                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                                    ) : null}
                                </FormControl>
                            )}
                        </Field>
                        <Field name="message">
                            {() => (
                                <FormControl
                                    isInvalid={errors?.message !== undefined && touched.message}
                                >
                                    <FormLabel htmlFor="message">Message</FormLabel>
                                    <Textarea
                                        variant="filled"
                                        name="message"
                                        value={values.message}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.message && touched.message ? (
                                        <FormErrorMessage>{errors.message}</FormErrorMessage>
                                    ) : null}
                                </FormControl>
                            )}
                        </Field>
                        <Button
                            isDisabled={isSubmitting || !(isValid && dirty)}
                            isLoading={isSubmitting}
                            type="submit"
                        >
                            Submit
                        </Button>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default ContactForm;
