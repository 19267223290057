import { Box, Flex, Spinner, Stack } from '@chakra-ui/react';
import OrganizationCard from './OrganizationCard';
import useOrganizations from '../../hooks/useOrganizations';
import { IOrganization } from './Organization.Interface';

const OrganizationsList = () => {
    const {
        isLoading: isLoadingOrganizations,
        allOrganizationsData,
        isError,
        error,
    } = useOrganizations();

    if (isLoadingOrganizations) {
        return (
            <Flex justifyContent="center">
                <Spinner
                    mt={20}
                    p={20}
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                />
            </Flex>
        );
    }

    if (isError) {
        return <Box pt={20}>Error: {error?.message}</Box>;
    }

    return (
        <Stack width={'100%'} mt={20} gap={10} justifyContent="center" alignItems={'center'}>
            {allOrganizationsData.map((organization: IOrganization) => (
                <OrganizationCard key={organization._id} {...organization} />
            ))}
        </Stack>
    );
};

export default OrganizationsList;
