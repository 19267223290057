import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Select,
    Stack,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { donateValidationSchema } from './donateValidation';
import useOrganizations from '../../hooks/useOrganizations';
import { IOrganization } from '../Organizations/Organization.Interface';
import { useParams } from 'react-router-dom';
import { IFormData } from './Donate.Interface';
import { BACKEND_HOST } from '../../config';

export const DonateForm = () => {
    const { allOrganizationsData = [] } = useOrganizations();
    const { id: organizationId } = useParams();
    const selectedOrganization = allOrganizationsData.find(
        (organization: IOrganization) => organization._id === organizationId
    );

    const handleSubmit = async (formData: IFormData) => {
        await sendDataToBackend(formData);
    };

    const sendDataToBackend = async (formData: IFormData) => {
        const data = JSON.stringify(formData);

        try {
            const response = await fetch(`${BACKEND_HOST}/api/stripe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: data,
            });

            if (response.ok) {
                console.log('Data sent');
                const body = await response.json();
                window.location.href = body.url;
            } else {
                console.error('Failed to send data');
            }
        } catch (error) {
            console.error('Error sending data:', error);
        }
    };

    return (
        <Box
            bgColor={'white'}
            color={'blue.800'}
            flexBasis={'15'}
            minW={'400px'}
            borderRadius={50}
            p={5}
        >
            <Formik
                initialValues={{
                    numberOfPixels: 0,
                    organization: selectedOrganization?.name ?? allOrganizationsData[0]?.name ?? '',
                    fullName: '',
                    email: '',
                    mobile: '',
                    hoverText: '',
                    hoverUrlLink: '',
                }}
                validationSchema={donateValidationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    const orgId = allOrganizationsData.find(
                        (organization: IOrganization) => organization.name === values.organization
                    )?._id;
                    setSubmitting(true);
                    handleSubmit({
                        quantity: values.numberOfPixels,
                        metadata: {
                            organizationId: orgId,
                            name: values.fullName,
                            email: values.email,
                            mobile: values.mobile,
                        },
                    }).finally(() => {
                        setSubmitting(false);
                    });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    dirty,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Flex
                            width="100%"
                            alignItems="top"
                            justifyContent={'space-between'}
                            p={5}
                            gap={3}
                            wrap="wrap"
                        >
                            {' '}
                            <Field name="organization">
                                {() => (
                                    <FormControl
                                    // isInvalid={
                                    //     errors?.organization !== undefined &&
                                    //     touched.organization
                                    // }
                                    >
                                        <FormLabel htmlFor="organization">Organization</FormLabel>
                                        <Select
                                            variant="filled"
                                            name="organization"
                                            value={values.organization}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            {allOrganizationsData.map(
                                                (organization: IOrganization) => (
                                                    <option
                                                        value={organization.name}
                                                        key={organization._id}
                                                    >
                                                        {organization.name}
                                                    </option>
                                                )
                                            )}
                                        </Select>
                                        {/* <Input
                                            variant="filled"
                                            name="organization"
                                            value={values.organization}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        /> */}
                                        {/* {errors.organization && touched.organization ? (
                                            <FormErrorMessage>
                                                {errors.organization}
                                            </FormErrorMessage>
                                        ) : null} */}
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="numberOfPixels">
                                {() => (
                                    <FormControl
                                        isInvalid={
                                            errors?.numberOfPixels !== undefined &&
                                            touched.numberOfPixels
                                        }
                                    >
                                        <FormLabel htmlFor="numberOfPixels">Pixels</FormLabel>
                                        <Input
                                            variant="filled"
                                            name="numberOfPixels"
                                            type="number"
                                            min={1}
                                            value={values.numberOfPixels}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.numberOfPixels && touched.numberOfPixels ? (
                                            <FormErrorMessage>
                                                {errors.numberOfPixels}
                                            </FormErrorMessage>
                                        ) : null}
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="hoverText">
                                {() => (
                                    <FormControl
                                        isInvalid={
                                            errors?.hoverText !== undefined && touched.hoverText
                                        }
                                    >
                                        <FormLabel htmlFor="hoverText">Hover Text</FormLabel>
                                        <Input
                                            variant="filled"
                                            name="hoverText"
                                            type="hoverText"
                                            value={values.hoverText}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.hoverText && touched.hoverText ? (
                                            <FormErrorMessage>{errors.hoverText}</FormErrorMessage>
                                        ) : null}
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="hoverUrlLink">
                                {() => (
                                    <FormControl
                                        isInvalid={
                                            errors?.hoverUrlLink !== undefined &&
                                            touched.hoverUrlLink
                                        }
                                    >
                                        <FormLabel htmlFor="hoverUrlLink">
                                            Hover Link to Display
                                        </FormLabel>
                                        <Input
                                            variant="filled"
                                            name="hoverUrlLink"
                                            type="hoverUrlLink"
                                            value={values.hoverUrlLink}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.hoverUrlLink && touched.hoverUrlLink ? (
                                            <FormErrorMessage>
                                                {errors.hoverUrlLink}
                                            </FormErrorMessage>
                                        ) : null}
                                    </FormControl>
                                )}
                            </Field>
                        </Flex>
                        <Stack spacing={3} p={5} pt={0} gap={3}>
                            {/* Full Name Field */}
                            <Field name="fullName">
                                {() => (
                                    <FormControl
                                        isInvalid={
                                            errors?.fullName !== undefined && touched.fullName
                                        }
                                    >
                                        <FormLabel htmlFor="fullName">Name</FormLabel>
                                        <Input
                                            variant="filled"
                                            name="fullName"
                                            placeholder="Display name"
                                            type="text"
                                            value={values.fullName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.fullName && touched.fullName ? (
                                            <FormErrorMessage>{errors.fullName}</FormErrorMessage>
                                        ) : null}
                                    </FormControl>
                                )}
                            </Field>
                            {/* Email Field */}
                            <Field name="email">
                                {() => (
                                    <FormControl
                                        isInvalid={errors?.email !== undefined && touched.email}
                                    >
                                        <FormLabel htmlFor="email">Email</FormLabel>
                                        <Input
                                            variant="filled"
                                            name="email"
                                            type="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.email && touched.email ? (
                                            <FormErrorMessage>{errors.email}</FormErrorMessage>
                                        ) : null}
                                    </FormControl>
                                )}
                            </Field>
                            {/* mobile Field */}
                            <Field name="mobile">
                                {() => (
                                    <FormControl
                                        isInvalid={errors?.mobile !== undefined && touched.mobile}
                                    >
                                        <FormLabel htmlFor="mobile">Phone Number</FormLabel>
                                        <Input
                                            variant="filled"
                                            name="mobile"
                                            type="tel"
                                            value={values.mobile}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />

                                        {errors.mobile && touched.mobile ? (
                                            <FormErrorMessage>{errors.mobile}</FormErrorMessage>
                                        ) : null}
                                    </FormControl>
                                )}
                            </Field>
                        </Stack>
                        <Stack alignItems={'center'}>
                            <Box> Total: ${(values.numberOfPixels * 10).toString()}</Box>
                            <Button
                                isDisabled={isSubmitting || !isValid}
                                isLoading={isSubmitting}
                                type="submit"
                                p={5}
                                w="fit-content"
                            >
                                Donate Now
                            </Button>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
