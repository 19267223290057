import { Stack, Link as ChakraLink, Text, Flex } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import routes from '../../constants/routes';
import links from '../../constants/SocialLinks';
import { navigationFilterFunc } from './navigationFilter';
import { SocialIcon } from 'react-social-icons';

const Footer = () => {
    return (
        <Stack justify={'space-between'} alignItems={'center'} mb={5}>
            <Flex
                mt={10}
                mb={3}
                py={2}
                px={5}
                color="#EAF1F7"
                bg="#081C3D"
                borderRadius="40px"
                boxShadow="0px 4px 30px #6592E0"
                textAlign={'center'}
                as={'nav'}
                w={'fit-content'}
                h={'fit-content'}
            >
                {links.map((link, index) => (
                    <ChakraLink
                        px={4}
                        key={index}
                        _hover={{
                            bg: '#4E96EC',
                            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)',
                        }}
                        style={{ borderRadius: '50px' }}
                    >
                        <SocialIcon url={link.Icon} bgColor="none" href={link.path} />
                    </ChakraLink>
                ))}
            </Flex>
            <Flex p={2} h="fit-content" color="#EAF1F7" alignItems="center" gap={6}>
                {routes.filter(navigationFilterFunc('footer')).map((route) => (
                    <ChakraLink
                        fontSize={'l'}
                        textDecoration="none"
                        as={ReactRouterLink}
                        key={route.path}
                        to={route.path}
                        aria-label={route.label}
                    >
                        {route.label}
                    </ChakraLink>
                ))}
            </Flex>
            <Text fontSize="xs" color="#EAF1F7">
                Copyright © 2024 Peace by Piece - All Rights Reserved.
            </Text>
        </Stack>
    );
};

export default Footer;
