import { Flex, Stack, Text, Heading, Spinner, Box } from '@chakra-ui/react';
import { DonateForm } from '../components/Donate/DonateForm';
import useOrganizations from '../hooks/useOrganizations';
import RenderPixelMap from '../components/Home/RenderPixelMap';

const Home = () => {
    const { isLoading: isLoadingOrganizations } = useOrganizations();
    return (
        <Stack spacing={10}>
            <Heading fontStyle="normal" fontWeight="800" fontSize="48px" lineHeight="58px">
                $10 per pixel
            </Heading>
            <Text fontStyle="normal" fontWeight="300" fontSize="26px" lineHeight="34px">
                <strong>1,000,000</strong> Pixels available. Each pixel bought means more dollars to
                support life-saving organizations in Israel.
            </Text>

            {isLoadingOrganizations ? (
                <Flex justifyContent="center">
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                    />
                </Flex>
            ) : (
                <>
                    <Flex alignItems={'start'} justifyContent={'center'} gap={'5'} wrap={'wrap'}>
                        <DonateForm />
                        <RenderPixelMap />
                    </Flex>
                </>
            )}

            {isLoadingOrganizations ? null : (
                <Box display={{ md: 'none' }}>
                    <RenderPixelMap />
                </Box>
            )}
        </Stack>
    );
};
export default Home;
