import { Heading, Stack } from '@chakra-ui/react';
import AboutTeamGrid from './AboutTeamGrid';

const AboutTeam = () => {
    return (
        <Stack>
            <Heading>Our Team</Heading>
            <AboutTeamGrid />
        </Stack>
    );
};

export default AboutTeam;
