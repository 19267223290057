import { Heading, Stack } from '@chakra-ui/react';
import FAQAccordion from '../components/Faq/FAQAccordion';

const FAQPage = () => {
    return (
        <Stack justifyContent={'space-between'} spacing={10} alignItems={'center'}>
            <Heading textAlign="center">Frequently Asked Questions</Heading>
            <FAQAccordion />
        </Stack>
    );
};

export default FAQPage;
