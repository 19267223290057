import { Stack, Divider } from '@chakra-ui/react';
import AmbassadorsList from '../components/Ambassadors/AmbassadorsList/AmbassadorsList';
import BecomeAmbassador from '../components/Ambassadors/BecomeAmbassador/BecomeAmbassador';

const AmbassadorsPage = () => {
    return (
        <>
            <Stack>
                <BecomeAmbassador />
                <Divider border="1.5px solid #FFFFFF" my={10} />
                <AmbassadorsList />
            </Stack>
        </>
    );
};

export default AmbassadorsPage;
