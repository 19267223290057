import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { BACKEND_HOST } from '../config';

export default function usePixels() {
    const {
        isLoading,
        error,
        isError,
        data: allPixelsData,
        refetch,
    } = useQuery({
        queryKey: ['allpixels'],
        queryFn: async () => {
            const { data } = await axios.get(`${BACKEND_HOST}/api/pixel`);
            return data;
        },
    });

    return {
        allPixelsData,
        isLoading,
        isError,
        error,
        refetch,
    };
}
